import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/bootstrap.scss"
import "animate.css/animate.min.css";
import { replaceLinks } from "../components/utils.js"

export default function services({content, service, chart}) {
    const description = content.shortName === 'srcc' ? replaceLinks(content.description_1, content.links) :
        content.description_1

    function serviceImages(name, banner) {
        switch(name) {
            case "maritime":
                if(banner) {
                    return <img className="parallax" src="../../stati/maritime.jpg" alt={name}/>
                }
                return <img src="/maritime-transportation.jpg" alt={name} />
            case "srcc":
                if(banner) {
                    return <img className="parallax" src="/srcc.jpg" alt={name}/>
                }
                break;
            default:
                return <div />
        }
    }

    return (
        <Layout>
            <Seo title={content.title}/>
            {serviceImages(content.shortName, true)}
            <div className="banner-cover" />
            <div className="banner animate__animated fadeInDownSmall">
                <h1 className="display-3"><b>{content.title}</b></h1>
            </div>
            <Container fluid className="bg-white" style={{padding: '3% 0 1px', boxShadow: 'inset 0 5px 5px #bebebe'}}>
                <Container className="mt-0">
                    <Row>
                        <Col lg={6}>
                            <h5>{content.subheader}</h5>
                            <p style={{whiteSpace: "pre-wrap"}}>{content.description}</p>
                            
                        </Col>
                        <Col lg={6}>
                            { content.shortName === 'srcc' ?
                                <div style={{height: "100%", width: "100%", margin: "1rem 0 1rem 0"}}>
                                    {chart}
                                </div>
                                :
                                (serviceImages(content.shortName, false))
                            }
                        </Col>
                        <Col lg={12} className="mt-3">
                            {content.shortName === 'srcc' &&
                                <p style={{whiteSpace: "pre-wrap"}} dangerouslySetInnerHTML={{ __html: description }} />
                            }
                        </Col>
                    </Row>
                </Container>
                <Container fluid className="bg-light" style={{padding: "0 15%"}}>
                    { content.items.length > 0 && content.items.map( (item, index) => (
                        <div className={"content-list " + (index === content.items.length-1 ? "last" : "")} key={index}>
                            <h5>{item.title}</h5>
                            <p>{item.description}</p>
                        </div>
                    ))}
                </Container>
            </Container>
        </Layout>
    )
}
