import React from 'react'
import Services from '../components/services'
import ServicesJSON from "../content/services.json"
import SRCCChart from "../components/srcc-chart.js"

export default function srcc() {
    const content = ServicesJSON.services.srcc
    return (
        <Services content={content} chart={<SRCCChart />} />
    )
}
