import React, {useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import JulyChartData from "../content/monthly_rankings_july_CA.json"
import axios from 'axios'

const configChart = ({ month, data }) => {
    return {
        chart: {
            type: 'line',
            zoomType: 'x',
        },
        title: {
            text: `Average ${month} Temperature for California`,
        },
        xAxis: {
            categories: data.year
        },
        yAxis: {
            title: {
                text: 'Temperature (F)'
            },
            plotLines: [{
                value: data.mean,
                color: 'black',
                dashStyle: 'shortdash',
                width: 2,
                label: {
                    text: 'Long-term Average'
                }
            }]
        },
        tooltip: {
            shared: true,
            crosshairs: true,
            borderWidth: 0,
        },
        series: [{
            name: 'Monthly Average',
            data: data.vals
        }],
        colors: ['maroon', '#003C71'],
        credits: false
    }
}

export default function SRCCChart() {
    const [chartData, setChartData] = useState({
        month: 'July',
        data: JulyChartData,
        // Keeps track if data was fetched already.
        // For some reason, axios keeps on fetching data
        // so this is so data is only fetched once
        fetched: false,
    })

    let date = new Date()
    date.setMonth(date.getMonth() - 1)
    const monthVal = date.toLocaleString('default', { month: 'short' }).toLowerCase()
    const params = {
        state: "CA",
        division: "00",
        element: "TEMP",
        month: `${monthVal}Value`
    }
    // Fetch data
    if(!chartData.fetched) {
        // axios.get(`http://localhost:8080/monthly_graphs/getMonthlyGraphsData/`, {
        axios.get(`https://www.srcc.tamu.edu/monthly_graphs/getMonthlyGraphsData/`, {
            params: params
        })
        .then(res => {
            // Only use data from year 2000+
            let idx = res.data.year.indexOf(2000)
            let length = res.data.year.length
            res.data.vals = res.data.vals.slice(idx, length)
            res.data.year = res.data.year.slice(idx, length)
            // Currently not using rolling average
            // res.data.rolling_avg = res.data.rolling_avg.slice(idx, length)

            // Update chart params
            setChartData({
                month: date.toLocaleString('default', { month: 'long' }),
                data: res.data,
                fetched: true,
            })
        })
        .catch(error => {
            console.error('There was an error!', error);
        })
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={configChart(chartData)}
        />
    )
}
